<template>
  <transition>
    <div :style="indent" :class="{
      'tree': true,
      'tree--root': depth === 0
    }">
      <div class="comment__content">
        <div class="comment-header">
          <div class="profile">

            <div class="profile__photo" v-if="autor.name === '' || autor.name === null"></div>
            <div v-else class="profile__logo">
              <span>
                {{ autor.name.substr(0, 1) }}
              </span>
            </div>
          </div>
          <span class="comment__autor">
            {{ autor.name }}
          </span>
          <span v-if="status === 'draft'" class="comment__status">
            {{ $t('player.tree_comentarios.awaiting_moderation') }}
          </span>
          <span v-if="status !== 'draft'" class="comment__status">
            {{ createdAt | moment }}
          </span>
        </div>
        <p>
          <b class="user-mark" v-if="repliedAt && depth > 0">@{{ repliedAt.name }}</b>
          {{ label }}
        </p>
      </div>
      <div class="comment__tree" v-show="showChildren">
        <TreeComentarios v-for="(node, index) in nodes" v-if="node.status != 'reproved'" :key="index"
          :nodes="node.replies" :label="node.comment" :autor="node.member" :repliedAt="autor"
          :createdAt="node.created_at" :isMine="node.is_mine" :liked="node.liked" :likes_count="node.likes_count"
          :status="node.status" :depth="depth + 1" :id="node.id" />
      </div>
      <div class="comment_answer" v-if="textAreaResp">
        <textarea v-model="myAnswer" id="myComment" type="text" class="comment_answer__text"
          :placeholder="$t('player.placeholder_comment_answer')"></textarea>
        <div class="comment__answer__actions">
          <ButtonPlay @click="answer(id)" type="normal">
            {{ $t('player.tree_comentarios.responder') }}
          </ButtonPlay>
          <ButtonPlay @click="textAreaResp = false">
            {{ $t('player.tree_comentarios.cancelar') }}
          </ButtonPlay>
        </div>
      </div>
      <div class="comment__actions">
        <div class="see-answers">
          <a v-if="!showChildren && nodes.length" href="" @click.prevent="toggleChildren" class="see-answers__link">
            <span>{{ $t('player.tree_comentarios.ver_respostas') }} ({{ nodes.length }})</span>
          </a>
          <a v-else v-show="nodes.length" href="" @click.prevent="toggleChildren" class="see-answers__link">
            <span>{{ $t('player.tree_comentarios.ocultar_respostas') }} ({{ nodes.length }})</span>
          </a>
        </div>
        <div class="comment__actions__items">
          <a v-if="liked === false" @click="commentLike({ id })" class="action-button">
            <IconSax primary size="1rem" name="like-1" />
            <span>{{ $t('player.tree_comentarios.curtir') }}</span>
          </a>
          <a v-else @click="commentUnlike({ id })" class="action-button">
            <IconSax primary size="1rem" name="like-1" type="bold" />
            <span>{{ $t('player.tree_comentarios.descurtir') }}</span>
          </a>
          <a @click="openAnswer(id)" class="action-button">
            <IconSax primary size="1rem" name="message-text" />
            <span>{{ $t('player.tree_comentarios.responder2') }}</span>
          </a>
          <a v-if="permission && status === 'draft'" @click="commentApprove(id)" class="action-button">
            <IconSax primary size="1rem" name="like" />
            <span>{{ $t('player.tree_comentarios.aprovar') }}</span>
          </a>
          <a v-if="permission" class="action-button" @click="commentDelete({ id })">
            <IconSax primary size="1rem" name="trash" />
            <span>{{ $t('player.tree_comentarios.deletar') }}</span>
          </a>
          <a v-if="isMine" @click="editComment(id, label)" class="action-button">
            <IconSax primary size="1rem" name="edit-2" />
            <span>{{ $t('player.tree_comentarios.editar') }}</span>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from "moment"
import Cookies from 'js-cookie'

import { mapActions } from "vuex"

export default {
  props: [
    "label",
    "nodes",
    "depth",
    "autor",
    "repliedAt",
    "createdAt",
    "status",
    "id",
    "isMine",
    "liked",
    "likes_count",
  ],
  data() {
    return {
      showChildren: false,
      permission: false,
      openRespId: "",
      textAreaResp: false,
      myAnswer: '',
      animeDelete: false,
      client: {
        width: 0
      }
    }
  },
  name: "TreeComentarios",
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    indent() {
      if (this.depth == 1) {
        return { transform: `translate(25px)` }
      }
    },
    isMobile() {
      return this.client.width <= 576
    }
  },
  methods: {
    ...mapActions({
      commentLike: 'courses/actionCommentLike',
      commentUnlike: 'courses/actionCommentUnlike',
      commentCreate: 'courses/actionCommentCreate',
      commentApprove: 'courses/actionCommentApprove',
      commentDelete: 'courses/actionCommentDelete',
    }),
    handleResize() {
      this.client.width = window.innerWidth;
    },
    async answer(parent_lesson_comment_id) {
      try {
        await this.commentCreate({
          parent_lesson_comment_id,
          comment: this.myAnswer,
        })
      } finally {
        this.myAnswer = "";
        this.textAreaResp = false;
      }
    },
    editComment(id, comentario) {
      this.$root.$emit('editComments', { id, comentario })
    },
    openAnswer(id) {
      this.openRespId = id;
      this.textAreaResp = true;
    },
    toggleChildren() {
      this.showChildren = !this.showChildren
    },
    getPermission() {
      let permission = Cookies.get('access_level')
      let typeView = Cookies.get('type_student')
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === '1') {
          this.permission = false
          return
        }
        this.permission = true
      } else {
        this.permission = false
      }
    },
  },
  mounted() {
    this.getPermission()
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY hh:mm")
    }
  }
}
</script>

<style lang="scss" scoped>
.tree {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;

  border-radius: 0.5rem;
  border: 1px solid var(--comment-border);
  /* border: 1px solid; */
}

.comment__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--fontcolor);
}

@media screen and (max-width: 320px) {
  .comment-header {
    flex-wrap: wrap;
  }
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.comment__autor {
  color: var(--fontcolor);

  /* Body/Bold/14px */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 1.05rem */
}

.comment__status {
  color: var(--neutral-gray-600, #6E6E71);
  /* Body/Regular/12px */
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 0.9rem */
}

.profile__logo {
  width: 45px;
  height: 45px;
  margin-left: -9px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 100%;
  }
}

.comment_answer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-700, #404043);
}

.comment_answer__text {
  all: unset;
  flex: 1;
}

.comment__answer__actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.comment__tree {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.profile .profile__photo {
  width: 35px;
  height: 35px;
  background: #ededf0;
  border-radius: 50%;
}

.profile .profile__logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--maincolor);
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.profile .profile__logo span {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.comment__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.comment__actions__items {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.see-answers {
  flex: 1;
  min-width: 8rem;
}

::v-deep .see-answers__link {

  span {
    color: var(--maincolor);

    /* Body/Semibold/14px */
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 1.05rem */
  }
}

.user-mark {
  color: var(--fontcolor-hover) !important;
}


::v-deep .action-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .svgCss {
    height: 1rem;
    width: 1rem;
  }

  .svgCss path {
    fill: var(--maincolor) !important;
  }

  span {
    color: var(--maincolor);

    /* Body/Semibold/14px */
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 1.05rem */
  }
}
</style>
